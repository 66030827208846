import { BeforeRequestHook, NormalizedOptions } from "ky"

import { ApiHttpClient } from "./ApiHttpClient"
import { applicationSettings } from "@/constants"

const { VIKING_API } = applicationSettings ?? {}

/**
 * API authentication hook
 *
 * @param response {Request} - Api request
 * @param _options {NormalizedOptions} - Api request options
 */
const setAccessToken: BeforeRequestHook = (request: Request, _options: NormalizedOptions) => {
  const accessToken = localStorage.getItem("access_token")

  if (accessToken) {
    request.headers.set("Authorization", `Bearer ${accessToken}`)
  }
}

export const vikingApiClient = new ApiHttpClient({ prefixUrl: VIKING_API, hooks: { beforeRequest: [setAccessToken] } })

export type { PaginatedList } from "./PaginatedList"
