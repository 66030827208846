export enum SessionSearchFilter {
  SESSION_ID = "SESSION_ID",
  SESSION_ALIAS = "SESSION_ALIAS"
}

export interface SessionSearchCriteria {
  filterType: SessionSearchFilter
  sessionId?: string
  sessionAlias?: string
}
