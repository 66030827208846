import { FC } from "react"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { ViewContainer } from "@/components/elements"

export const HomePage: FC = () => {
  const { t } = useTranslation()

  return (
    <ViewContainer>
      <Typography variant="body2" color="text.primary" className="application-acronym">
        {t("APPLICATION.NAME")}
      </Typography>
    </ViewContainer>
  )
}
