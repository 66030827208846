export type KeyValueMatch = (key: string, value: any) => boolean

export function filterData<T extends object>(data: T, matchFunction: KeyValueMatch): Partial<T> | null {
  if (typeof data !== "object" || data === null) {
    return null
  }

  const result: Partial<T> = {}

  for (const [key, value] of Object.entries(data)) {
    if (matchFunction(key, value)) {
      result[key as keyof T] = value
    }

    if (typeof value === "object" && value !== null) {
      const nestedResult = filterData(value, matchFunction)

      if (nestedResult && Object.keys(nestedResult).length > 0) {
        result[key as keyof T] = nestedResult as T[keyof T]
      }
    }
  }

  return result
}
