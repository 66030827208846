import { FC } from "react"
import { Box, Button } from "@mui/material"
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded"
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded"

import { ThemeContext } from "@/contexts"

export const ThemeModeToggle: FC = () => {
  return (
    <ThemeContext.Consumer>
      {({ themeMode, toggleThemeMode }) => (
        <Box sx={{ maxWidth: "32px" }}>
          <Button
            variant="text"
            onClick={toggleThemeMode}
            size="small"
            aria-label="button to toggle theme"
            sx={{ minWidth: "32px", height: "32px", p: "4px" }}
          >
            {themeMode === "dark" ? <WbSunnyRoundedIcon fontSize="small" /> : <ModeNightRoundedIcon fontSize="small" />}
          </Button>
        </Box>
      )}
    </ThemeContext.Consumer>
  )
}
