import { vikingApiClient } from "@/http-client"
import { SessionSearchFilter, Session, SessionSearchCriteria } from "@/types"

export async function fetchSessions(searchCriteria: SessionSearchCriteria): Promise<Session[]> {
  let requestUrl: string | null = null

  // Map search criteria to API search params
  switch (searchCriteria.filterType) {
    case SessionSearchFilter.SESSION_ID:
      // searchParams.sessionId = searchCriteria.sessionId!
      requestUrl = `session-management/session/${searchCriteria.sessionId!}`
      break

    case SessionSearchFilter.SESSION_ALIAS:
      // searchParams.sessionAlias = searchCriteria.sessionAlias!
      requestUrl = `session-management/session/alias/${searchCriteria.sessionAlias!}`
      break
  }

  const session = await vikingApiClient.get<Session>(requestUrl)
  return Array.of(session)
}

export async function fetchSession(sessionId: number | string): Promise<Session> {
  const session = await vikingApiClient.get<Session>(`session-management/session/${sessionId}`)
  return session
}
