import "./CallAProPage.module.scss"

import { FC, useState } from "react"
import { HeadProvider, Title, Link } from "react-head"
import Script from "script-tag-in-react"

import { ViewContainer } from "@/components/elements"
import { applicationSettings } from "@/constants"

const { CALLAPRO_WEBSITE_URL } = applicationSettings ?? {}

export const CallAProPage: FC = () => {
  const [isReactLoaded, setIsReactLoaded] = useState(false)
  const [isReactDOMLoaded, setIsReactDOMLoaded] = useState(false)

  const onCallAProReady = async () => {
    const bootstrapApplication = (window as any).eLocal?.bootstrapApplication

    if (typeof bootstrapApplication === "function") {
      await bootstrapApplication({
        apiKey: "{INSERT_API_KEY}",
        targetElement: "elocal-application-root",
        need_id: "11000-14647",
        options: {
          compact: false,
          selectionInputType: "select",
          showSummaryScreen: true,
          skipCompletedSteps: false
        }
      })
    }
  }

  return (
    <ViewContainer>
      <HeadProvider>
        <Title>Call A Pro (Embedded)</Title>
        <Link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap" />
        <Link rel="stylesheet" href={`${CALLAPRO_WEBSITE_URL}/third-party/eLocal.embed.bundle.css`} />
      </HeadProvider>

      <div id="elocal-application-root"></div>

      <Script crossOrigin="anonymous" src="https://unpkg.com/react@18/umd/react.production.min.js" onReady={() => setIsReactLoaded(true)} />

      {isReactLoaded && (
        <Script
          crossOrigin="anonymous"
          src="https://unpkg.com/react-dom@18/umd/react-dom.production.min.js"
          onReady={() => setIsReactDOMLoaded(true)}
        />
      )}

      {isReactLoaded && isReactDOMLoaded && <Script src={`${CALLAPRO_WEBSITE_URL}/third-party/eLocal.embed.bundle.js`} onReady={onCallAProReady} />}
    </ViewContainer>
  )
}
