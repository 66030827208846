import { FC } from "react"
import { LinearProgress } from "@mui/material"

import { ViewContainer } from "@/components/elements"

export const LoadingPage: FC = () => {
  return (
    <ViewContainer>
      <LinearProgress />
    </ViewContainer>
  )
}
