import { useEffect, useMemo, useState } from "react"
import { Box, FormControl, Grid, InputLabel, MenuItem, TextField } from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import ReactJson, { ThemeKeys } from "react-json-view"
import { useTranslation } from "react-i18next"

import { filterData, KeyValueMatch } from "@/utils/search.utils"

export interface JsonTreeViewProps<T extends object> {
  name: string
  data: T
}

export const JsonTreeView = <T extends object>({ name, data }: JsonTreeViewProps<T>) => {
  const { t } = useTranslation()

  const [filterValue, setFilterValue] = useState("")

  const [filteredJsonData, setFilteredJsonData] = useState<Partial<T> | null>()

  const [selectedTheme, setSelectedTheme] = useState<ThemeKeys>("codeschool")

  const themes = useMemo<ThemeKeys[]>(() => {
    return [
      "apathy",
      "apathy:inverted",
      "ashes",
      "bespin",
      "brewer",
      "bright:inverted",
      "bright",
      "chalk",
      "codeschool",
      "colors",
      "eighties",
      "embers",
      "flat",
      "google",
      "grayscale",
      "grayscale:inverted",
      "greenscreen",
      "harmonic",
      "hopscotch",
      "isotope",
      "marrakesh",
      "mocha",
      "monokai",
      "ocean",
      "paraiso",
      "pop",
      "railscasts",
      "rjv-default",
      "shapeshifter",
      "shapeshifter:inverted",
      "solarized",
      "summerfruit",
      "summerfruit:inverted",
      "threezerotwofour",
      "tomorrow",
      "tube",
      "twilight"
    ] as ThemeKeys[]
  }, [])

  useEffect(() => {
    const matchFunction: KeyValueMatch = (key: string, value: any) => {
      const lowercaseFilterValue = filterValue.toLowerCase()

      const isKeyMatch = key.toString().toLowerCase().includes(lowercaseFilterValue)

      const isValueMatch = value?.toString()?.toLowerCase()?.includes(lowercaseFilterValue)

      const isMatch = isKeyMatch || isValueMatch

      return isMatch
    }

    const filtered = filterData(data, matchFunction)

    setFilteredJsonData(filtered)
  }, [data, filterValue])

  const handleThemeChange = (event: SelectChangeEvent) => {
    setSelectedTheme(event.target.value as ThemeKeys)
  }

  if (!data) {
    return null
  }

  return (
    <Box mb={5}>
      <Grid container justifyContent="flex-end" spacing={5}>
        <Grid item>
          <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth>
              <InputLabel id="theme-select-label">{t("COMMON.THEME")}</InputLabel>
              <Select labelId="theme-select-label" value={selectedTheme} label={t("COMMON.THEME")} onChange={handleThemeChange}>
                {themes.map((theme, index) => (
                  <MenuItem key={index} value={theme}>
                    {theme}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item>
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <TextField
                label={t("COMMON.FILTER")}
                value={filterValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFilterValue(event.target.value)
                }}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Box mt={2}>
        {typeof filteredJsonData === "object" && filteredJsonData !== null && (
          <ReactJson
            indentWidth={4}
            collapsed={3}
            collapseStringsAfterLength={100}
            name={name}
            src={filteredJsonData}
            theme={selectedTheme}
            style={{
              fontSize: "0.75rem",
              overflow: "auto"
            }}
          />
        )}
      </Box>
    </Box>
  )
}
