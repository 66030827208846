import { FC, useMemo, useState } from "react"
import { debounce } from "lodash-es"
import { Field } from "react-final-form"
import { Box, Button, FormControl, Stack, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import Ajv, { ErrorObject, ValidateFunction } from "ajv"
import CodeMirror from "@uiw/react-codemirror"
import { json } from "@codemirror/lang-json"
import { lineNumbers, placeholder } from "@codemirror/view"
import { solarizedLight, solarizedDark } from "@uiw/codemirror-theme-solarized"

const ajv = new Ajv()

interface JsonEditorProps {
  label: string
  name: string
  disabled?: boolean
  readOnly?: boolean
  schema: object
  sample: object
  initialValue: unknown
}

function isValidJSON(json: string) {
  try {
    JSON.parse(json)
    return true
  } catch (error) {
    return false
  }
}

export const JsonEditor: FC<JsonEditorProps> = ({ label, name, initialValue, disabled, readOnly, schema, sample }) => {
  const theme = useTheme()

  const { t } = useTranslation()

  const validateSchema = useMemo<ValidateFunction>(() => ajv.compile(schema), [schema])

  const initialJsonValue = useMemo(() => (typeof initialValue === "object" ? JSON.stringify(initialValue, null, 2) : ""), [initialValue])

  const sampleAsJsonString = useMemo<string>(() => JSON.stringify(sample, null, 2), [sample])

  const [isEditable, setIsEditable] = useState(false)

  const [errors, setErrors] = useState<string[]>([])

  const [jsonValue, setJsonValue] = useState<string>(initialJsonValue)

  const extensions = [json(), lineNumbers(), placeholder(isEditable ? sampleAsJsonString : "")]

  const editJson = () => {
    setIsEditable(true)
    setJsonValue(initialJsonValue)
  }

  const cancelEditing = () => {
    setIsEditable(false)
  }

  const copySampleToClipboard = () => {
    navigator.clipboard.writeText(sampleAsJsonString)
  }

  const formatJson = () => {
    try {
      const formattedJson = JSON.stringify(JSON.parse(jsonValue), null, 2)
      setJsonValue(formattedJson)
    } catch (error: any) {
      setErrors([error.message])
    }
  }

  const handleChange = (json: string) => {
    if (isEditable) {
      setJsonValue(json)
      validateJson()
    }
  }

  const validateJson = debounce(() => {
    try {
      if (!isValidJSON(jsonValue)) {
        setErrors([t("JSON_EDITOR.JSON_INVALID")])
      } else {
        const isValidSchema = validateSchema(JSON.parse(jsonValue))

        if (isValidSchema) {
          setErrors([])
        } else {
          const validationErrors: ErrorObject[] = validateSchema.errors!
          const errorMessages = validationErrors.map((error) => error.message).filter(Boolean)

          setErrors(errorMessages as string[])
        }
      }
    } catch (error: any) {
      setErrors([error.message])
    }
  }, 300)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
      }}
    >
      <Field name={name}>
        {({ input }) => {
          const saveChange = () => {
            const value = JSON.parse(jsonValue)

            const isValidSchema = validateSchema(value)

            if (isValidSchema) {
              input.onChange(value)
              setIsEditable(false)
            }
          }

          return (
            <FormControl
              sx={{
                flex: 1,
                flexDirection: "column"
              }}
            >
              <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1 }}>
                <small>{t(label)}</small>
              </Typography>

              <CodeMirror
                extensions={extensions}
                width="100%"
                minHeight="250px"
                theme={theme.palette.mode === "light" ? solarizedLight : solarizedDark}
                value={jsonValue}
                onChange={handleChange}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                editable={!disabled && !readOnly && isEditable}
                readOnly={readOnly}
              />

              <>
                {errors.length > 0 ? (
                  <>
                    <Box sx={{ color: "error.main" }}>
                      <p>⚠️</p>

                      {errors.map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                    </Box>
                  </>
                ) : (
                  <Box sx={{ color: "success.main" }}>
                    <>✅</>
                  </Box>
                )}
              </>

              <Stack direction="row" justifyContent="flex-end" spacing={1} paddingY={1}>
                {isEditable ? (
                  <>
                    <Button variant="outlined" onClick={cancelEditing}>
                      {t("COMMON.CANCEL")}
                    </Button>

                    <Button variant="contained" color="primary" onClick={validateJson}>
                      {t("JSON_EDITOR.VALIDATE_JSON")}
                    </Button>

                    <Button variant="contained" color="secondary" onClick={formatJson}>
                      {t("JSON_EDITOR.FORMAT_JSON")}
                    </Button>

                    <Button variant="contained" color="info" onClick={copySampleToClipboard}>
                      {t("JSON_EDITOR.COPY_SAMPLE_JSON_TO_CLIPBOARD")}
                    </Button>

                    <Button variant="contained" color="success" onClick={saveChange} disabled={errors.length > 0}>
                      {t("JSON_EDITOR.SAVE_JSON")}
                    </Button>
                  </>
                ) : (
                  <>
                    {!readOnly && !disabled && (
                      <Button variant="outlined" onClick={editJson}>
                        {t("JSON_EDITOR.EDIT_JSON")}
                      </Button>
                    )}
                  </>
                )}
              </Stack>
            </FormControl>
          )
        }}
      </Field>
    </Box>
  )
}
