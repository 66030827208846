import { FC, Fragment, ReactNode } from "react"
import { Box, Grid } from "@mui/material"
import countryCodeLookup, { Country } from "country-code-lookup"
import { useTranslation } from "react-i18next"
import { isEmpty, isNil } from "lodash-es"
import dayjs from "dayjs"

import { Address } from "@/components/elements"
import { Listing } from "@/types"

interface ListingDetailsProps {
  listing: Listing
}

export const ListingDetails: FC<ListingDetailsProps> = ({ listing }) => {
  const { t } = useTranslation()

  const {
    account_id,
    imported_listing_id,
    listing_id,
    external_listing_id,
    address,
    name,
    listing_source,
    listing_type,
    number_of_employees,
    offers_round_the_clock_services,
    published_at,
    time_zone,
    total_number_of_reviews,
    urls,
    contact_method
  } = listing

  const { address_line_1, address_line_2, city, latitude, longitude, state_or_province, country_code, postal_code, suppress_address } = address

  const countryData: Country | null = country_code ? countryCodeLookup.byIso(country_code) : null

  const displayData: {
    label: string
    value: string | number | boolean | ReactNode
    isHidden?: boolean
  }[] = [
    {
      label: t("LISTINGS_PAGE.IMPORTED_LISTING_ID"),
      value: imported_listing_id
    },
    {
      label: t("LISTINGS_PAGE.EXTERNAL_LISTING_ID"),
      value: external_listing_id
    },
    {
      label: t("LISTINGS_PAGE.LISTING_ID"),
      value: listing_id
    },
    {
      label: t("LISTINGS_PAGE.LISTING_NAME"),
      value: name
    },
    {
      label: t("LISTINGS_PAGE.ACCOUNT_ID"),
      value: account_id
    },
    {
      label: t("LISTINGS_PAGE.PUBLISHED_AT"),
      value: dayjs(published_at).format("MM/DD/YYYY @ hh:mm A")
    },
    {
      label: t("LISTINGS_PAGE.WEBSITES"),
      value: urls.map(({ url }, index) => (
        <a key={index} href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )),
      isHidden: isEmpty(urls)
    },
    {
      label: t("LISTINGS_PAGE.PHONE_NUMBER"),
      value: contact_method.matching_phone,
      isHidden: isEmpty(contact_method?.matching_phone)
    },
    {
      label: t("LISTINGS_PAGE.TIMEZONE"),
      value: time_zone
    },
    {
      label: t("LISTINGS_PAGE.LISTING_SOURCE"),
      value: listing_source
    },
    {
      label: t("LISTINGS_PAGE.LISTING_TYPE"),
      value: listing_type
    },
    {
      label: t("LISTINGS_PAGE.NUMBER_OF_EMPLOYEES"),
      value: number_of_employees
    },
    {
      label: t("LISTINGS_PAGE.AROUND_THE_CLOCK_SERVICE"),
      value: t(offers_round_the_clock_services ? "COMMON.YES" : "COMMON.NO")
    },
    {
      label: t("LISTINGS_PAGE.NUMBER_OF_REVIEWS"),
      value: total_number_of_reviews
    },
    {
      label: t("LISTINGS_PAGE.LISTING_ADDRESS"),
      value: (
        <Address
          address_line_1={address_line_1}
          address_line_2={address_line_2}
          city={city}
          state_or_province={state_or_province}
          country={countryData?.country}
          postal_code={postal_code}
        />
      )
    },
    {
      label: t("LISTINGS_PAGE.ADDRESS.SUPPRESS"),
      value: t(suppress_address ? "COMMON.YES" : "COMMON.NO")
    },
    {
      label: t("LISTINGS_PAGE.GEO_LOCATION.MAPS_LOCATION"),
      value: (() => {
        const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`

        return (
          <a href={googleMapsLink} target="_blank" rel="noreferrer">
            {googleMapsLink}
          </a>
        )
      })(),
      isHidden: isNil(latitude) || isNil(longitude)
    }
  ]

  return (
    <Box px={2}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        {displayData.map(({ label, value, isHidden }, index) => {
          return isHidden ? null : (
            <Fragment key={index}>
              <Grid key={index} item xs={12} md={3}>
                <b>{label}</b>
              </Grid>
              <Grid item xs={12} md={7}>
                {value}
              </Grid>
            </Fragment>
          )
        })}
      </Grid>
    </Box>
  )
}
