export enum ListingSearchFilter {
  EXTERNAL_LISTING_ID = "EXTERNAL_LISTING_ID",
  IMPORTED_LISTING_ID = "IMPORTED_LISTING_ID",
  LISTING_ID = "LISTING_ID",
  PHONE_NUMBER = "PHONE_NUMBER",
  LISTING_SOURCE = "LISTING_SOURCE",
  LISTING_NAME_AND_STATE_OR_PROVINCE = "LISTING_NAME_AND_STATE",
  LISTING_NAME_AND_POSTAL_CODE = "LISTING_NAME_AND_POSTAL_CODE"
}

export interface ListingSearchCriteria {
  filterType: ListingSearchFilter
  externalListingId?: string
  importedListingId?: string
  listingId?: string
  listingName?: string
  listingSource?: string
  phoneNumber?: string
  stateOrProvince?: string
  postalCode?: string
}
