import ky, { Hooks, KyInstance, KyResponse, Options } from "ky"

/**
 * Generic API instance
 */
export class ApiHttpClient {
  kyInstance: KyInstance

  constructor({ hooks, prefixUrl }: { hooks?: Hooks; prefixUrl: string }) {
    this.kyInstance = ky.extend({
      hooks,
      prefixUrl,
      retry: {
        limit: 0
      },
      timeout: false
    })
  }

  /**
   * Parse response as JSON if it's valid, otherwise fallback
   * @param response {T} - Api response
   * @returns - parsed JSON or string
   */
  private parseResponse = <T = string>(response: KyResponse) => {
    return response.body ? (response.json() as T) : (response.body as unknown as T)
  }

  /**
   * Perform GET request
   * @param path {string} - api path (with no leading slash character)
   * @param options @type {Options} - request options
   * @returns {ResponseBody} - API Response
   */
  get = async <ResponseBody>(path: string, options: Options = {}): Promise<ResponseBody> => {
    const response = await this.kyInstance.get(path, options)
    return this.parseResponse<ResponseBody>(response)
  }

  /**
   * Perform POST request
   * @param path {string} - api path (with no leading slash character)
   * @param json {RequestBody} - post body
   * @param options @type {Options} - request options
   * @returns {ResponseBody} - API Response
   */
  post = async <RequestBody = unknown, ResponseBody = unknown>(
    path: string,
    json: RequestBody,
    options: Omit<Options, "json" | "body"> = {}
  ): Promise<ResponseBody> => {
    const response = await this.kyInstance.post(path, { ...options, json })
    return this.parseResponse<ResponseBody>(response)
  }

  /**
   * Perform PUT request
   * @param path {string} - api path (with no leading slash character)
   * @param json {RequestBody} - post body
   * @param options @type {Options} - request options
   * @returns {ResponseBody} - API Response
   */
  put = async <RequestBody = unknown, ResponseBody = unknown>(
    path: string,
    json: RequestBody,
    options: Omit<Options, "json" | "body"> = {}
  ): Promise<ResponseBody> => {
    const response = await this.kyInstance.put(path, { ...options, json })
    return this.parseResponse<ResponseBody>(response)
  }

  /**
   * Perform PATCH request
   * @param path {string} - api path (with no leading slash character)
   * @param json {RequestBody} - post body
   * @param options @type {Options} - request options
   * @returns {ResponseBody} - API Response
   */
  patch = async <RequestBody = unknown, ResponseBody = unknown>(
    path: string,
    json: RequestBody,
    options: Omit<Options, "json" | "body"> = {}
  ): Promise<ResponseBody> => {
    const response = await this.kyInstance.patch(path, { ...options, json })
    return this.parseResponse<ResponseBody>(response)
  }

  /**
   * Perform DELETE request
   * @param path {string} - api path (with no leading slash character)
   * @param options @type {Options} - request options
   * @returns {ResponseBody} - API Response
   */
  delete = async <ResponseBody = unknown>(path: string, options: Options = {}): Promise<ResponseBody> => {
    const response = await this.kyInstance.delete(path, options)
    return this.parseResponse<ResponseBody>(response)
  }
}
