import { createContext } from "react"
import { PaletteMode } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { noop } from "lodash-es"

import { createTheme } from "@/theme"

interface ThemeContextType {
  theme: Theme
  themeMode: PaletteMode
  toggleThemeMode: () => void
}

export const DEFAULT_THEME_MODE: PaletteMode = "dark"

export const ThemeContext = createContext<ThemeContextType>({
  theme: createTheme(DEFAULT_THEME_MODE),
  themeMode: DEFAULT_THEME_MODE,
  toggleThemeMode: noop
})
