import "./LoginPage.module.scss"

import { FC } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

import { DynamicContent, ViewContainer } from "@/components/elements"
import { useAuthentication } from "@/hooks"

export const LoginPage: FC = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { isAuthenticated, loginInProgress, error } = useAuthentication()

  const authentication_error = searchParams.get("error")
  const authentication_error_details = searchParams.get("error_details")

  const state = searchParams.get("state")

  if (state) {
    const decodedState = JSON.parse(atob(state))
    const { returnUrl } = decodedState

    if (isAuthenticated) {
      navigate(returnUrl ? decodeURIComponent(returnUrl) : "/")
    }
  }

  return (
    <ViewContainer title="LOGIN_PAGE.TITLE">
      <DynamicContent
        isLoading={loginInProgress}
        error={authentication_error ?? error}
        errorMessage={authentication_error_details ?? "LOGIN_PAGE.LOGIN_ERROR"}
      >
        <>{/* empty content */}</>
      </DynamicContent>
    </ViewContainer>
  )
}
