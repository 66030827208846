import { FC } from "react"
import { Field } from "react-final-form"
import { v4 as uuidv4 } from "uuid"
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useTranslation } from "react-i18next"

export type DropdownOptionValue = number | string | undefined

export interface DropdownOption {
  label: string
  value: DropdownOptionValue
}

interface DropdownProps {
  label: string
  name: string
  placeholder?: string
  withEmptyOption?: boolean
  options: DropdownOption[]
  disabled?: boolean
  readOnly?: boolean
  variant?: "standard" | "filled" | "outlined"
}

export const Dropdown: FC<DropdownProps> = (props) => {
  const { t } = useTranslation()

  const { name, label, placeholder, options = [], withEmptyOption, disabled, readOnly, variant } = props

  const inputId = uuidv4()

  return (
    <Box sx={{ display: "flex" }}>
      <Field name={name}>
        {({ input, meta }) => {
          const showError = Boolean(meta.touched && meta.error)

          const handleChange = (event: SelectChangeEvent<DropdownOptionValue>, _child: React.ReactNode) => {
            if (!disabled && !readOnly) {
              input.onChange(event.target.value)
            }
          }

          return (
            <FormControl variant={variant} sx={{ flex: 1 }} error={showError}>
              <InputLabel id={`label-${inputId}`}>{t(label)}</InputLabel>

              <Select
                id={`input-${inputId}`}
                labelId={`label-${inputId}`}
                label={t(label)}
                name={input.name}
                placeholder={placeholder}
                value={input.value}
                onChange={handleChange}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                size="medium"
                inputProps={{
                  disabled,
                  readOnly
                }}
              >
                {withEmptyOption && <MenuItem value={undefined}>&nbsp;</MenuItem>}

                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {t(option.label)}
                  </MenuItem>
                ))}
              </Select>

              {showError && <FormHelperText>⚠️ {t(meta.error)}</FormHelperText>}
            </FormControl>
          )
        }}
      </Field>
    </Box>
  )
}
