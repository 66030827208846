import { FC, Fragment } from "react"

interface AddressProps {
  address_line_1: string
  address_line_2?: string
  city: string
  state_or_province: string
  country?: string
  postal_code: string
}

export const Address: FC<AddressProps> = (props) => {
  const { address_line_1, address_line_2, city, state_or_province, country, postal_code } = props

  const address = [
    address_line_1, // Address line 1
    address_line_2, // Address line 2
    [city, state_or_province, country].filter(Boolean).join(", "), // City, State/Province and Country
    postal_code // Postal code
  ].filter(Boolean)

  return (
    <address>
      {address.map((line, index) => (
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
    </address>
  )
}
