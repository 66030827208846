interface ApplicationSettings {
  CALLAPRO_WEBSITE_URL: string // Callapro Website URL
  OAUTH_CLIENT_ID: string // Cognito Client ID
  OAUTH_AUTHENTICATION_URL: string // Cognito Authentication URL
  OAUTH_REDIRECT_URL: string // Cognito Redirect URL
  OAUTH_LOGOUT_REDIRECT_URL: string // Cognito Logout Redirect URL
  VIKING_API: string // Viking API URL
}

export const applicationSettings: ApplicationSettings = {
  CALLAPRO_WEBSITE_URL: import.meta.env.VITE_CALLAPRO_WEBSITE_URL as string,
  OAUTH_CLIENT_ID: import.meta.env.VITE_OAUTH_CLIENT_ID as string,
  OAUTH_AUTHENTICATION_URL: import.meta.env.VITE_OAUTH_AUTHENTICATION_URL as string,
  OAUTH_REDIRECT_URL: import.meta.env.VITE_OAUTH_REDIRECT_URL as string,
  OAUTH_LOGOUT_REDIRECT_URL: import.meta.env.VITE_OAUTH_LOGOUT_REDIRECT_URL as string,
  VIKING_API: import.meta.env.VITE_VIKING_API as string
}
