import { Navigate, Outlet, useLocation } from "react-router-dom"

import { useAuthentication } from "@/hooks"

export const AuthenticatedRoutes = () => {
  const location = useLocation()
  const { isAuthenticated } = useAuthentication()

  if (isAuthenticated) {
    return <Outlet />
  }

  const returnUrl = [location.pathname, location.search].join("")
  const loginUrl = `/login?returnUrl=${encodeURIComponent(returnUrl)}`

  return <Navigate to={loginUrl} />
}
