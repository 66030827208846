import { omit } from "lodash-es"

import { vikingApiClient } from "@/http-client"
import { ListingSearchFilter, Listing, ListingResponse, ListingSearchCriteria } from "@/types"

export async function fetchListings(searchCriteria: ListingSearchCriteria): Promise<Listing[]> {
  const searchParams: Record<string, string | number | boolean> = {}

  // Map search criteria to API search params
  switch (searchCriteria.filterType) {
    case ListingSearchFilter.IMPORTED_LISTING_ID:
      searchParams.importedListingId = searchCriteria.importedListingId!
      break

    case ListingSearchFilter.LISTING_ID:
      searchParams.listingId = searchCriteria.listingId!
      break

    case ListingSearchFilter.EXTERNAL_LISTING_ID:
      searchParams.externalListingId = searchCriteria.externalListingId!
      break

    case ListingSearchFilter.PHONE_NUMBER:
      searchParams.organicPhone = searchCriteria.phoneNumber!
      break

    case ListingSearchFilter.LISTING_NAME_AND_STATE_OR_PROVINCE:
      searchParams.listingName = searchCriteria.listingName!
      searchParams.stateOrProvince = searchCriteria.stateOrProvince!
      break

    case ListingSearchFilter.LISTING_NAME_AND_POSTAL_CODE:
      searchParams.listingName = searchCriteria.listingName!
      searchParams.postalCode = searchCriteria.postalCode!
      break

    case ListingSearchFilter.LISTING_SOURCE:
      searchParams.listingSource = searchCriteria.listingSource!
      break
  }

  const listings = await vikingApiClient.get<Listing[]>(`listing-processor/listing`, { searchParams })
  return listings
}

export async function fetchListing(importedListingId: number | string): Promise<ListingResponse> {
  const listing = await vikingApiClient.get<ListingResponse>(`listing-processor/listing/${importedListingId}`)
  return listing
}

export async function updateListing(importedListingId: number | string, listingId: number | string, listing: Listing): Promise<Listing> {
  const listingUpdate = omit<Listing>(listing, ["categories", "geographies_containing", "intersecting_geographies"])

  const updatedListing = await vikingApiClient.post<Partial<Listing>, Listing>(`listing-processor/listing`, listingUpdate, {
    searchParams: { listingId, importedListingId }
  })

  return updatedListing
}
