import { FC } from "react"
import { Form } from "react-final-form"
import { useTranslation } from "react-i18next"
import { FormApi, SubmissionErrors } from "final-form"
import { Box, Button, Grid, Stack } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import SearchIcon from "@mui/icons-material/Search"

import { Dropdown, DropdownOption, TextField } from "@/components/forms/inputs"
import { ListingSearchFilter, ListingSearchCriteria } from "@/types"

const dropdownOptions: DropdownOption[] = [
  {
    label: "LISTINGS_PAGE.EXTERNAL_LISTING_ID",
    value: ListingSearchFilter.EXTERNAL_LISTING_ID
  },
  {
    label: "LISTINGS_PAGE.IMPORTED_LISTING_ID",
    value: ListingSearchFilter.IMPORTED_LISTING_ID
  },
  {
    label: "LISTINGS_PAGE.LISTING_ID",
    value: ListingSearchFilter.LISTING_ID
  },
  {
    label: "LISTINGS_PAGE.PHONE_NUMBER",
    value: ListingSearchFilter.PHONE_NUMBER
  },
  {
    label: "LISTINGS_PAGE.LISTING_SOURCE",
    value: ListingSearchFilter.LISTING_SOURCE
  },
  {
    label: "LISTINGS_PAGE.LISTING_NAME_AND_STATE_OR_PROVINCE",
    value: ListingSearchFilter.LISTING_NAME_AND_STATE_OR_PROVINCE
  },
  {
    label: "LISTINGS_PAGE.LISTING_NAME_AND_POSTAL_CODE",
    value: ListingSearchFilter.LISTING_NAME_AND_POSTAL_CODE
  }
]

const buttonStyles = {
  height: "55px"
}

const validate = (values: ListingSearchCriteria) => {
  const { filterType, importedListingId, listingId, externalListingId, listingName, phoneNumber, stateOrProvince, postalCode, listingSource } = values

  const errors: Partial<Record<keyof ListingSearchCriteria, string | undefined>> = {}

  if (!filterType) {
    errors.filterType = "LISTINGS_PAGE.FILTER_TYPE_REQUIRED"
  }

  switch (filterType) {
    case ListingSearchFilter.EXTERNAL_LISTING_ID:
      if (!externalListingId) {
        errors.externalListingId = "LISTINGS_PAGE.EXTERNAL_LISTING_ID_REQUIRED"
      }
      break

    case ListingSearchFilter.IMPORTED_LISTING_ID:
      if (!importedListingId) {
        errors.importedListingId = "LISTINGS_PAGE.IMPORTED_LISTING_ID_REQUIRED"
      }
      break

    case ListingSearchFilter.LISTING_ID:
      if (!listingId) {
        errors.listingId = "LISTINGS_PAGE.LISTING_ID_REQUIRED"
      }
      break

    case ListingSearchFilter.PHONE_NUMBER:
      if (!phoneNumber) {
        errors.phoneNumber = "LISTINGS_PAGE.PHONE_NUMBER_REQUIRED"
      }
      break

    case ListingSearchFilter.LISTING_SOURCE:
      if (!listingSource) {
        errors.listingSource = "LISTINGS_PAGE.LISTING_SOURCE_REQUIRED"
      }
      break

    case ListingSearchFilter.LISTING_NAME_AND_STATE_OR_PROVINCE:
      if (!listingName) {
        errors.listingName = "LISTINGS_PAGE.LISTING_NAME_REQUIRED"
      }

      if (!stateOrProvince) {
        errors.stateOrProvince = "LISTINGS_PAGE.STATE_OR_PROVINCE_REQUIRED"
      }
      break

    case ListingSearchFilter.LISTING_NAME_AND_POSTAL_CODE:
      if (!listingName) {
        errors.listingName = "LISTINGS_PAGE.LISTING_NAME_REQUIRED"
      }

      if (!postalCode) {
        errors.postalCode = "LISTINGS_PAGE.ADDRESS.POSTAL_CODE_REQUIRED"
      }
      break
  }

  return errors
}

interface ListingSearchFormProps {
  initialValues?: Partial<ListingSearchCriteria>
  isPending: boolean
  onSubmit: (
    values: ListingSearchCriteria,
    form: FormApi<ListingSearchCriteria, Partial<ListingSearchCriteria>>,
    callback?: (errors?: SubmissionErrors) => void
  ) => SubmissionErrors | Promise<SubmissionErrors> | void
}

export const ListingSearchForm: FC<ListingSearchFormProps> = ({ initialValues, isPending, onSubmit }) => {
  const { t } = useTranslation()

  const inputVariant = "outlined"

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Form<ListingSearchCriteria>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, values, valid }) => {
          const { filterType } = values as ListingSearchCriteria

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Dropdown name="filterType" label="Filter type" options={dropdownOptions} withEmptyOption />
                </Grid>

                <Grid item xs={12} md={6}>
                  {filterType === ListingSearchFilter.EXTERNAL_LISTING_ID && (
                    <TextField name="externalListingId" label="LISTINGS_PAGE.EXTERNAL_LISTING_ID" variant={inputVariant} />
                  )}

                  {filterType === ListingSearchFilter.IMPORTED_LISTING_ID && (
                    <TextField name="importedListingId" label="LISTINGS_PAGE.IMPORTED_LISTING_ID" variant={inputVariant} />
                  )}

                  {filterType === ListingSearchFilter.LISTING_ID && (
                    <TextField name="listingId" label="LISTINGS_PAGE.LISTING_ID" variant={inputVariant} />
                  )}

                  {filterType === ListingSearchFilter.PHONE_NUMBER && (
                    <TextField name="phoneNumber" label="LISTINGS_PAGE.PHONE_NUMBER" variant={inputVariant} />
                  )}

                  {filterType === ListingSearchFilter.LISTING_SOURCE && (
                    <TextField name="listingSource" label="LISTINGS_PAGE.LISTING_SOURCE" variant={inputVariant} />
                  )}

                  {filterType === ListingSearchFilter.LISTING_NAME_AND_STATE_OR_PROVINCE && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <TextField name="listingName" label="LISTINGS_PAGE.LISTING_NAME" variant={inputVariant} />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField name="stateOrProvince" label="LISTINGS_PAGE.STATE_OR_PROVINCE" variant={inputVariant} />
                      </Grid>
                    </Grid>
                  )}

                  {filterType === ListingSearchFilter.LISTING_NAME_AND_POSTAL_CODE && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <TextField name="listingName" label="LISTINGS_PAGE.LISTING_NAME" variant={inputVariant} />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField name="postalCode" label="LISTINGS_PAGE.ADDRESS.POSTAL_CODE" variant={inputVariant} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    display: "flex",
                    alignSelf: "flex-start",
                    justifyContent: "space-around"
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Button variant="outlined" color="primary" onClick={() => form.reset()} endIcon={<RestartAltIcon />} sx={buttonStyles}>
                      {t("COMMON.RESET")}
                    </Button>

                    <LoadingButton
                      variant="contained"
                      color="primary"
                      disabled={!valid}
                      loading={isPending}
                      onClick={() => form.submit()}
                      type="submit"
                      endIcon={<SearchIcon />}
                      sx={buttonStyles}
                    >
                      {t("COMMON.SEARCH")}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </Box>
  )
}
