import { useEffect, useContext } from "react"
import { IAuthContext, AuthContext } from "react-oauth2-code-pkce"

const ID_TOKEN_KEY = "id_token"
const ACCESS_TOKEN_KEY = "access_token"

export function useAuthentication() {
  const { token, tokenData, idToken, idTokenData, logIn, logOut, loginInProgress, error } = useContext<IAuthContext>(AuthContext)

  const isAuthenticated = Boolean(token && idToken)

  useEffect(() => {
    if (idToken && token) {
      localStorage.setItem(ID_TOKEN_KEY, idToken)
      localStorage.setItem(ACCESS_TOKEN_KEY, token)
    } else {
      localStorage.removeItem(ID_TOKEN_KEY)
      localStorage.removeItem(ACCESS_TOKEN_KEY)
    }
  }, [idToken, token])

  return {
    token,
    tokenData,
    idToken,
    idTokenData,
    isAuthenticated,
    logIn,
    logOut,
    loginInProgress,
    error
  }
}
