import { FC } from "react"
import { Form } from "react-final-form"
import { useTranslation } from "react-i18next"
import { FormApi, SubmissionErrors } from "final-form"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"
import { countries, Country } from "country-code-lookup"
import timezones, { TimeZone } from "timezones-list"

import { Checkbox, DisplayField, Dropdown, DropdownOption, JsonEditor, TextField } from "@/components/forms/inputs"
import { JsonSchema } from "@/json-schema"
import { JsonSchemaSample } from "@/json-schema/samples"
import { Listing } from "@/types"

const COUNTRIES: DropdownOption[] = countries.map((country: Country) => ({
  label: country.country,
  value: country.iso2
}))

const TIMEZONES: DropdownOption[] = timezones.map((timezone: TimeZone) => ({
  label: timezone.label,
  value: timezone.tzCode
}))

const validate = (values: Listing) => {
  const { address, contact_method, name, number_of_employees, time_zone, total_number_of_reviews } = values

  const { address_line_1, city, latitude, longitude, state_or_province, country_code, postal_code } = address ?? {}

  const { matching_phone } = contact_method ?? {}

  const errors: Partial<Record<keyof Listing, any>> = {
    address: {},
    contact_method: {}
  }

  if (!name) {
    errors.imported_listing_id = "LISTINGS_PAGE.LISTING_NAME_REQUIRED"
  }

  if (!time_zone) {
    errors.time_zone = "LISTINGS_PAGE.TIMEZONE_REQUIRED"
  }

  if (!matching_phone) {
    errors.contact_method.matching_phone = "LISTINGS_PAGE.PHONE_NUMBER_REQUIRED"
  }

  if (number_of_employees && isNaN(number_of_employees)) {
    errors.number_of_employees = "LISTINGS_PAGE.NUMBER_OF_EMPLOYEES_INVALID"
  }

  if (total_number_of_reviews && isNaN(total_number_of_reviews)) {
    errors.total_number_of_reviews = "LISTINGS_PAGE.NUMBER_OF_REVIEWS_INVALID"
  }

  if (!address_line_1) {
    errors.address.address_line_1 = "LISTINGS_PAGE.ADDRESS.LINE_1_REQUIRED"
  }

  if (!city) {
    errors.address.city = "LISTINGS_PAGE.ADDRESS.CITY_REQUIRED"
  }

  if (!state_or_province) {
    errors.address.state_or_province = "LISTINGS_PAGE.ADDRESS.STATE_OR_PROVINCE_REQUIRED"
  }

  if (!country_code || !countries.find((country) => country.iso2 === country_code)) {
    errors.address.country_code = "LISTINGS_PAGE.ADDRESS.COUNTRY_REQUIRED"
  }

  if (!postal_code) {
    errors.address.postal_code = "LISTINGS_PAGE.ADDRESS.POSTAL_CODE_REQUIRED"
  }

  if ((latitude && isNaN(latitude)) || latitude < -90 || latitude > 90) {
    errors.address.latitude = "LISTINGS_PAGE.GEO_LOCATION.LATITUDE_INVALID"
  }

  if ((longitude && isNaN(longitude)) || longitude < -180 || longitude > 180) {
    errors.address.longitude = "LISTINGS_PAGE.GEO_LOCATION.LONGITUDE_INVALID"
  }

  if (latitude && !longitude) {
    errors.address.longitude = "LISTINGS_PAGE.GEO_LOCATION.LONGITUDE_REQUIRED"
  }

  if (!latitude && longitude) {
    errors.address.latitude = "LISTINGS_PAGE.GEO_LOCATION.LATITUDE_REQUIRED"
  }

  return errors
}

interface EditListingDetailsFormProps {
  initialValues?: Partial<Listing>
  isPending: boolean
  onCancel: () => void
  onSubmit: (
    values: Listing,
    form: FormApi<Listing, Partial<Listing>>,
    callback?: (errors?: SubmissionErrors) => void
  ) => SubmissionErrors | Promise<SubmissionErrors> | void
}

export const EditListingDetailsForm: FC<EditListingDetailsFormProps> = ({ initialValues, isPending, onCancel, onSubmit }) => {
  const { t } = useTranslation()

  const inputVariant = "standard"

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Form<Listing>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, valid }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <Grid container columnSpacing={10} rowSpacing={5}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DisplayField name="imported_listing_id" label="LISTINGS_PAGE.IMPORTED_LISTING_ID" variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DisplayField name="listing_id" label="LISTINGS_PAGE.LISTING_ID" variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DisplayField name="external_listing_id" label="LISTINGS_PAGE.EXTERNAL_LISTING_ID" variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DisplayField name="account_id" label="LISTINGS_PAGE.ACCOUNT_ID" variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField name="name" label="LISTINGS_PAGE.LISTING_NAME" variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DisplayField name="published_at" label="LISTINGS_PAGE.PUBLISHED_AT" variant={inputVariant} dataType="date" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField name="contact_method.matching_phone" label="LISTINGS_PAGE.PHONE_NUMBER" variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Dropdown name="time_zone" label="LISTINGS_PAGE.TIMEZONE" options={TIMEZONES} variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField name="listing_source" label="LISTINGS_PAGE.LISTING_SOURCE" variant={inputVariant} readOnly disabled />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField name="listing_type" label="LISTINGS_PAGE.LISTING_TYPE" variant={inputVariant} readOnly disabled />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField name="number_of_employees" label="LISTINGS_PAGE.NUMBER_OF_EMPLOYEES" variant={inputVariant} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField name="total_number_of_reviews" label="LISTINGS_PAGE.NUMBER_OF_REVIEWS" variant={inputVariant} />
                  </Grid>
                </Grid>

                <Grid item xs={12} marginTop={7} marginBottom={2}>
                  <Typography variant="h6" gutterBottom>
                    {t("LISTINGS_PAGE.ADDRESS.TITLE")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container columnSpacing={5} rowSpacing={5}>
                    <Grid item xs={12}>
                      <TextField name="address.address_line_1" label="LISTINGS_PAGE.ADDRESS.LINE_1" variant={inputVariant} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name="address.address_line_2" label="LISTINGS_PAGE.ADDRESS.LINE_2" variant={inputVariant} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField name="address.city" label="LISTINGS_PAGE.ADDRESS.CITY" variant={inputVariant} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField name="address.state_or_province" label="LISTINGS_PAGE.ADDRESS.STATE_OR_PROVINCE" variant={inputVariant} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Dropdown name="address.country_code" label="LISTINGS_PAGE.ADDRESS.COUNTRY" options={COUNTRIES} variant={inputVariant} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField name="address.postal_code" label="LISTINGS_PAGE.ADDRESS.POSTAL_CODE" variant={inputVariant} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} marginTop={7} marginBottom={2}>
                  <Typography variant="h6" gutterBottom>
                    {t("LISTINGS_PAGE.ADDRESS.SETTINGS")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <DisplayField name="address.status" label="LISTINGS_PAGE.ADDRESS.STATUS" variant={inputVariant} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Checkbox name="address.revalidate" label="LISTINGS_PAGE.ADDRESS.REVALIDATE" readOnly disabled />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Checkbox name="address.suppress_address" label="LISTINGS_PAGE.ADDRESS.SUPPRESS" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} marginTop={7} marginBottom={2}>
                  <Typography variant="h6" gutterBottom>
                    {t("LISTINGS_PAGE.GEO_LOCATION.TITLE")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container columnSpacing={10} rowSpacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField name="address.latitude" label="LISTINGS_PAGE.GEO_LOCATION.LATITUDE" variant={inputVariant} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField name="address.longitude" label="LISTINGS_PAGE.GEO_LOCATION.LONGITUDE" variant={inputVariant} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} marginTop={7} marginBottom={2}>
                  <Typography variant="h6" gutterBottom>
                    {t("LISTINGS_PAGE.WORKING_HOURS_AND_WEBSITES")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} marginTop={3}>
                  <Checkbox name="offers_round_the_clock_services" label="LISTINGS_PAGE.AROUND_THE_CLOCK_SERVICE" />
                </Grid>

                {/*
                  // TODO: Enable this field once API is updated to handle business hours
                  <Grid item xs={12} marginTop={5}>
                    <JsonEditor
                      name="business_hours"
                      label="LISTINGS_PAGE.BUSINESS_HOURS"
                      schema={JsonSchema.listingBusinessHours}
                      sample={JsonSchemaSample.listingBusinessHours}
                      initialValue={initialValues?.business_hours}
                    />
                  </Grid>
                */}

                <Grid item xs={12} marginTop={5}>
                  <JsonEditor
                    name="urls"
                    label="LISTINGS_PAGE.WEBSITES"
                    schema={JsonSchema.listingUrls}
                    sample={JsonSchemaSample.listingUrls}
                    initialValue={initialValues?.urls}
                  />
                </Grid>

                <Stack direction="row" justifyContent="flex-end" spacing={1} marginY={7}>
                  <Button variant="outlined" color="primary" onClick={onCancel}>
                    {t("COMMON.CANCEL")}
                  </Button>

                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!valid}
                    loading={isPending}
                    onClick={() => form.submit()}
                    type="submit"
                    endIcon={<SaveIcon />}
                  >
                    {t("COMMON.SAVE")}
                  </LoadingButton>
                </Stack>
              </Box>
            </form>
          )
        }}
      />
    </Box>
  )
}
