import { FC, ReactNode, useState } from "react"
import { CssBaseline, PaletteMode } from "@mui/material"
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles"

import { ThemeContext, DEFAULT_THEME_MODE } from "@/contexts"
import { createTheme } from "@/theme"

const THEME_MODE_KEY = "ui.theme"

interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }): JSX.Element => {
  const [themeMode, setThemeMode] = useState<PaletteMode>((localStorage.getItem(THEME_MODE_KEY) as PaletteMode) ?? DEFAULT_THEME_MODE)

  const theme = createTheme(themeMode)

  const toggleThemeMode = (): void => {
    const nextThemeMode = themeMode === "light" ? "dark" : "light"
    setThemeMode(nextThemeMode)
    localStorage.setItem(THEME_MODE_KEY, nextThemeMode)
  }

  return (
    <ThemeContext.Provider value={{ theme, themeMode, toggleThemeMode }}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  )
}
