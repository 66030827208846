import { FC } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { Alert, Box, Button, Collapse, Fade, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash-es"

import { applicationDefaults } from "@/constants"
import { DynamicContent, JsonTreeView, ViewContainer } from "@/components/elements"
import { fetchSession } from "@/services/sessions.service"

const { ANIMATION_TIMEOUT } = applicationDefaults

export const SessionDetailsPage: FC = () => {
  const { t } = useTranslation()

  const { sessionId } = useParams()

  const {
    isFetching,
    error,
    data: session,
    refetch: refetchSession
  } = useQuery({
    enabled: ["string"].includes(typeof sessionId),
    queryKey: ["sessions", sessionId],
    queryFn: async () => fetchSession(sessionId!)
  })

  const actionButtons = (
    <Fade in={true} timeout={ANIMATION_TIMEOUT}>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => refetchSession()}>
          {t("COMMON.REFRESH")}
        </Button>
      </Stack>
    </Fade>
  )

  return (
    <ViewContainer title="SESSIONS_PAGE.SESSION_DETAILS" actionButtons={actionButtons}>
      <DynamicContent isLoading={isFetching} error={error} errorMessage="SESSIONS_PAGE.ERROR_FETCHING_SESSION">
        <>
          {isEmpty(session) ? (
            <Alert severity="info">{t("SESSIONS_PAGE.NO_SESSION_FOUND", { sessionId })}</Alert>
          ) : (
            <Collapse in={true} timeout={ANIMATION_TIMEOUT}>
              <Box mb={5}>
                <JsonTreeView name="session" data={session} />
              </Box>
            </Collapse>
          )}
        </>
      </DynamicContent>
    </ViewContainer>
  )
}
