import { Routes, Route } from "react-router-dom"

// Layouts
import { MainLayout } from "@/components/layouts"

// Routes
import { AnonymousRoutes, AuthenticatedRoutes } from "./components/elements"

// Views
import { CallAProPage } from "@/components/pages/CallAProPage"
import { HomePage } from "@/components/pages/HomePage"
import { ListingSearchPage } from "@/components/pages/ListingSearchPage"
import { ListingDetailsPage } from "@/components/pages/ListingDetailsPage"
import { LoginPage } from "@/components/pages/LoginPage"
import { NotFoundPage } from "@/components/pages/NotFoundPage"
import { SessionSearchPage } from "@/components/pages/SessionSearchPage"
import { SessionDetailsPage } from "@/components/pages/SessionDetailsPage"

export const routes = (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<HomePage />} />

      <Route path="login" element={<LoginPage />} />

      <Route path="call-a-pro" element={<CallAProPage />} />

      <Route element={<AnonymousRoutes />} />

      <Route element={<AuthenticatedRoutes />}>
        {/* Listings management */}
        <Route path="listings" element={<ListingSearchPage />} />
        <Route path="listings/:listingId" element={<ListingDetailsPage />} />
        <Route path="listings/:listingId/edit" element={<ListingDetailsPage />} />

        {/* Sessions management */}
        <Route path="sessions" element={<SessionSearchPage />} />
        <Route path="sessions/:sessionId" element={<SessionDetailsPage />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </Routes>
)
