import { FC } from "react"
import { Field } from "react-final-form"
import { Box, FormControl, TextField as MuiTextField } from "@mui/material"
import { useTranslation } from "react-i18next"

interface TextFieldProps {
  label: string
  name: string
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  variant?: "outlined" | "standard" | "filled"
}

export const TextField: FC<TextFieldProps> = ({ label, name, placeholder, disabled, readOnly, variant }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: "flex" }}>
      <Field name={name}>
        {({ input, meta }) => {
          const showError = meta.touched && meta.error

          const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!disabled && !readOnly) {
              input.onChange(event.target.value)
            }
          }

          return (
            <FormControl sx={{ flex: 1 }}>
              <MuiTextField
                label={t(label)}
                name={input.name}
                placeholder={placeholder}
                value={input.value}
                onChange={handleChange}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                size="medium"
                {...(showError
                  ? {
                      error: true,
                      helperText: <>⚠️ {t(meta.error)}</>
                    }
                  : {})}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={disabled}
                InputProps={{
                  readOnly
                }}
                variant={variant}
              />
            </FormControl>
          )
        }}
      </Field>
    </Box>
  )
}
