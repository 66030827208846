import { FC } from "react"
import { Link } from "react-router-dom"
import { byIso, Country } from "country-code-lookup"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash-es"
import dayjs from "dayjs"
import { Box, Button, Card, CardActions, CardContent, Grid, Paper, Stack, Typography } from "@mui/material"

import { Listing } from "@/types"

interface ListingSearchResultProps {
  listing: any
}

export const ListingSearchResult: FC<ListingSearchResultProps> = ({ listing }) => {
  const { t } = useTranslation()

  const { imported_listing_id, listing_id, external_listing_id, address, name, listing_source, published_at, contact_method } = listing as Listing

  const { address_line_1, address_line_2, city, state_or_province, country_code, postal_code } = address

  const countryData: Country | null = country_code ? byIso(country_code) : null

  const formattedAddress = [address_line_1, address_line_2, city, state_or_province, countryData?.country, postal_code].filter(Boolean).join(", ")

  return (
    <Box marginBottom={3}>
      <Paper>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" color="text.secondary">
              {name}
            </Typography>

            <Grid container spacing={2} paddingTop={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1">
                  {t("LISTINGS_PAGE.LISTING_ADDRESS")}: <b>{formattedAddress}</b>
                  {!isEmpty(contact_method?.matching_phone) && (
                    <>
                      <br />
                      {t("LISTINGS_PAGE.PHONE_NUMBER")}: <b>{contact_method.matching_phone}</b>
                    </>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body1">
                  {t("LISTINGS_PAGE.IMPORTED_LISTING_ID")}: <b>{imported_listing_id}</b>
                  <br />
                  {t("LISTINGS_PAGE.EXTERNAL_LISTING_ID")}: <b>{external_listing_id}</b>
                  <br />
                  {t("LISTINGS_PAGE.LISTING_ID")}: <b>{listing_id}</b>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body1">
                  {t("LISTINGS_PAGE.PUBLISHED_AT")}: <b>{dayjs(published_at).format("DD/MM/YYYY")}</b>
                  <br />
                  {t("LISTINGS_PAGE.LISTING_SOURCE")}: <b>{listing_source}</b>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions
            sx={{
              padding: 2,
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Stack direction="row" spacing={2}>
              <Button variant="contained" color="primary" component={Link} to={`/listings/${imported_listing_id}`}>
                {t("COMMON.VIEW")}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Paper>
    </Box>
  )
}
