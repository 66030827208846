import { TAuthConfig } from "react-oauth2-code-pkce"

import { applicationSettings } from "@/constants"

const { OAUTH_AUTHENTICATION_URL, OAUTH_CLIENT_ID, OAUTH_REDIRECT_URL, OAUTH_LOGOUT_REDIRECT_URL } = applicationSettings ?? {}

export const authConfig: TAuthConfig = {
  autoLogin: false,
  clearURL: false,
  decodeToken: false,
  authorizationEndpoint: `${OAUTH_AUTHENTICATION_URL}/oauth2/authorize`,
  tokenEndpoint: `${OAUTH_AUTHENTICATION_URL}/oauth2/token`,
  logoutEndpoint: `${OAUTH_AUTHENTICATION_URL}/logout`,
  clientId: OAUTH_CLIENT_ID,
  redirectUri: OAUTH_REDIRECT_URL,
  logoutRedirect: OAUTH_LOGOUT_REDIRECT_URL,
  extraLogoutParameters: {
    redirect_uri: OAUTH_REDIRECT_URL,
    response_type: "code"
  },
  scope: "aws.cognito.signin.user.admin email openid profile phone",
  refreshTokenExpiryStrategy: "renewable"
}
