// https://transform.tools/json-to-json-schema
// https://json-schema-faker.js.org/

export const listingBusinessHours = {
  "open_hours": {
    "monday": ["0530-1830"],
    "tuesday": ["0530-1830"],
    "wednesday": ["0530-1830"],
    "thursday": ["0530-1830"],
    "friday": ["0530-1900"],
    "saturday": ["0600-1830"],
    "sunday": ["0600-1830"]
  }
}

export const listingUrls = [
  {
    "type": "website_url",
    "url": "http://www.recoverylawgroup.com"
  }
]

export const JsonSchemaSample = {
  listingBusinessHours,
  listingUrls
}
