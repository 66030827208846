import "./ListingSearchPage.module.scss"

import { FC, useCallback, useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { Alert, Divider } from "@mui/material"
import { isEmpty, isNil } from "lodash-es"
import { useTranslation } from "react-i18next"
import queryString from "query-string"

import { fetchListings } from "@/services/listings.service"
import { DynamicContent, ViewContainer } from "@/components/elements"
import { ListingSearchForm } from "@/components/forms"
import { ListingSearchCriteria } from "@/types"
import { ListingSearchResult } from "./ListingSearchResult"

export const ListingSearchPage: FC = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const [searchCount, setSearchCount] = useState(0)

  const [isSearchQueryEnabled, setIsSearchQueryEnabled] = useState(false)

  const initSearchCriteria = useCallback(
    (): ListingSearchCriteria => ({
      filterType: searchParams.get("filterType") as ListingSearchCriteria["filterType"],
      externalListingId: searchParams.get("externalListingId") as ListingSearchCriteria["externalListingId"],
      importedListingId: searchParams.get("importedListingId") as ListingSearchCriteria["importedListingId"],
      listingId: searchParams.get("listingId") as ListingSearchCriteria["listingId"],
      listingName: searchParams.get("listingName") as ListingSearchCriteria["listingName"],
      listingSource: searchParams.get("listingSource") as ListingSearchCriteria["listingSource"],
      phoneNumber: searchParams.get("phoneNumber") as ListingSearchCriteria["phoneNumber"],
      stateOrProvince: searchParams.get("stateOrProvince") as ListingSearchCriteria["stateOrProvince"],
      postalCode: searchParams.get("postalCode") as ListingSearchCriteria["postalCode"]
    }),
    [searchParams]
  )

  const [searchCriteria, setSearchCriteria] = useState<ListingSearchCriteria>(initSearchCriteria)

  const {
    isFetching,
    error,
    data: listings
  } = useQuery({
    enabled: isSearchQueryEnabled,
    queryKey: ["listings", { searchCriteria, searchCount }],
    queryFn: async () => fetchListings(searchCriteria!)
  })

  useEffect(() => {
    // Update search criteria from URL parameters
    const criteria = initSearchCriteria()
    setSearchCriteria(criteria)
  }, [initSearchCriteria, location])

  const handleSubmit = (criteria: ListingSearchCriteria): void => {
    // Set search criteria
    setSearchCriteria(criteria)
    setSearchCount((count) => count + 1)

    // Update URL parameters
    const urlParams = new URLSearchParams(queryString.stringify(criteria, { skipEmptyString: true, skipNull: true }))
    setSearchParams(urlParams)

    // Trigger search query
    if (!isSearchQueryEnabled) {
      setIsSearchQueryEnabled(true)
    }
  }

  return (
    <ViewContainer title="LISTINGS_PAGE.LISTINGS_SEARCH">
      <ListingSearchForm initialValues={searchCriteria} isPending={isFetching} onSubmit={handleSubmit} />

      <Divider aria-hidden="true" sx={{ marginY: 3 }} />

      <DynamicContent isLoading={isFetching} isVisible={!isNil(listings)} error={error} errorMessage="LISTINGS_PAGE.ERROR_FETCHING_LISTINGS">
        {isEmpty(listings) ? (
          <Alert severity="info">{t("LISTINGS_PAGE.NO_LISTINGS_FOUND")}</Alert>
        ) : (
          <>{listings?.map((listing, index) => <ListingSearchResult key={index} listing={listing} />)}</>
        )}
      </DynamicContent>
    </ViewContainer>
  )
}
