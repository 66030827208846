import styles from "./Navigation.module.scss"

import { FC, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { AppBar, Box, Button, Divider, Drawer, MenuItem, SvgIcon, Toolbar, Typography } from "@mui/material"

import MenuIcon from "@mui/icons-material/Menu"

import { ThemeModeToggle } from "@/components/elements"
import { ElocalLogo } from "@/assets/svgs"
import { useAuthentication } from "@/hooks"

interface MenuEntry {
  path: string
  label: string
}

const MENU_ENTRIES: MenuEntry[] = [
  {
    path: "/listings",
    label: "LISTINGS_PAGE.LISTINGS"
  },
  {
    path: "/sessions",
    label: "SESSIONS_PAGE.SESSIONS"
  }
]

export const Navigation: FC = () => {
  const [searchParams] = useSearchParams()

  const { t } = useTranslation()

  const { isAuthenticated, idTokenData, logIn, loginInProgress, logOut } = useAuthentication()

  const [open, setOpen] = useState(false)

  const handleLogin = () => {
    const returnUrl = searchParams.get("returnUrl")
    const state = btoa(JSON.stringify({ returnUrl }))

    logIn(state)
  }

  const handleLogOut = () => {
    logOut()
  }

  const toggleDrawer = (isOpen: boolean) => () => {
    setOpen(isOpen)
  }

  return (
    <AppBar
      position="relative"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        paddingTop: 1
      }}
    >
      {/* Desktop navigation */}
      <Toolbar
        variant="regular"
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexShrink: 0,
          borderRadius: "999px",
          bgcolor: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)",
          backdropFilter: "blur(24px)",
          maxHeight: 40,
          border: "1px solid",
          borderColor: "divider",
          boxShadow:
            theme.palette.mode === "light"
              ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
              : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)"
        })}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            ml: "-18px",
            px: 0
          }}
        >
          <Link to="/">
            <Box className={styles["application-logo-container"]}>
              <SvgIcon className={styles["application-logo"]}>
                <ElocalLogo />
              </SvgIcon>
            </Box>
          </Link>

          {isAuthenticated ? (
            <Box sx={{ display: { xs: "none", md: "flex" } }} gap={1}>
              {MENU_ENTRIES.map(({ label, path }, index) => (
                <Link key={index} to={path}>
                  <MenuItem
                    sx={{
                      py: "6px",
                      px: "12px",
                      borderRadius: 1
                    }}
                    selected={location.pathname === path}
                  >
                    {t(label)}
                  </MenuItem>
                </Link>
              ))}
            </Box>
          ) : (
            <Typography variant="h6" color="text.primary" className="application-acronym">
              {t("APPLICATION.ACRONYM")}
            </Typography>
          )}
        </Box>

        {idTokenData && (
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "flex-end",
              marginRight: 2
            }}
          >
            <Typography variant="body2" color="text.primary">
              {t(idTokenData?.name)}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 1,
            alignItems: "center"
          }}
        >
          <ThemeModeToggle />

          <Button color="primary" size="small" variant="contained" disabled={loginInProgress} onClick={isAuthenticated ? handleLogOut : handleLogin}>
            {isAuthenticated ? t("APPLICATION.SIGN_OUT") : t("APPLICATION.SIGN_IN")}
          </Button>
        </Box>

        {/* Mobile navigation */}
        <Box sx={{ display: { sm: "", md: "none" } }}>
          <Button variant="text" color="primary" aria-label="menu" onClick={toggleDrawer(true)} sx={{ minWidth: "30px", p: "4px" }}>
            <MenuIcon />
          </Button>
          <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
            <Box
              sx={{
                minWidth: "60dvw",
                p: 2,
                backgroundColor: "background.paper",
                flexGrow: 1
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  flexGrow: 1
                }}
              >
                <ThemeModeToggle />
              </Box>

              {MENU_ENTRIES.map(({ label, path }, index) => (
                <Link key={index} to={path}>
                  <MenuItem>{t(label)}</MenuItem>
                </Link>
              ))}

              <Divider />

              {isAuthenticated && (
                <MenuItem>
                  <Button
                    color="primary"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={loginInProgress}
                    onClick={isAuthenticated ? handleLogOut : handleLogin}
                  >
                    {isAuthenticated ? t("APPLICATION.SIGN_OUT") : t("APPLICATION.SIGN_IN")}
                  </Button>
                </MenuItem>
              )}
            </Box>
          </Drawer>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
