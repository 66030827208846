import { FC, Fragment } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Box, Button, Card, CardActions, CardContent, List, ListItem, ListItemText, Paper, Stack } from "@mui/material"
import { isEmpty } from "lodash-es"

import { Session } from "@/types"

interface SessionSearchResultProps {
  session: Session
}

export const SessionSearchResult: FC<SessionSearchResultProps> = ({ session }) => {
  const { t } = useTranslation()

  const { sessionId, sessionAliases } = session

  const formattedSessionAliases = isEmpty(sessionAliases) ? (
    "N/A"
  ) : (
    <>
      {sessionAliases.map((sessionAlias: string, index: number) => (
        <Fragment key={index}>
          <label>{sessionAlias}</label>
          <br />
        </Fragment>
      ))}
    </>
  )

  return (
    <Box marginBottom={3}>
      <Paper>
        <Card>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText primary={t("SESSIONS_PAGE.SESSION_ID")} secondary={sessionId} />
              </ListItem>
            </List>

            <List>
              <ListItem>
                <ListItemText primary={t("SESSIONS_PAGE.SESSION_ALIASES")} secondary={formattedSessionAliases} />
              </ListItem>
            </List>
          </CardContent>

          <CardActions
            sx={{
              padding: 2,
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Stack direction="row" spacing={2}>
              <Button variant="contained" color="primary" component={Link} to={`/sessions/${sessionId}`}>
                {t("COMMON.VIEW")}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Paper>
    </Box>
  )
}
