import "./ErrorPage.module.scss"

import { FC } from "react"
import Alert from "@mui/material/Alert"

import { ViewContainer } from "@/components/elements"

export const ErrorPage: FC = () => {
  return (
    <ViewContainer>
      <Alert severity="error">Oops! An unexpected error occured. Please try again.</Alert>
    </ViewContainer>
  )
}
