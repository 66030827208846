import "./SessionSearchPage.module.scss"

import { FC, useCallback, useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { Alert, Divider } from "@mui/material"
import { isEmpty, isNil } from "lodash-es"
import { useTranslation } from "react-i18next"
import queryString from "query-string"

import { fetchSessions } from "@/services/sessions.service"
import { DynamicContent, ViewContainer } from "@/components/elements"
import { SessionSearchForm } from "@/components/forms"
import { SessionSearchCriteria } from "@/types"
import { SessionSearchResult } from "./SessionSearchResult"

export const SessionSearchPage: FC = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const [searchCount, setSearchCount] = useState(0)

  const [isSearchQueryEnabled, setIsSearchQueryEnabled] = useState(false)

  const initSearchCriteria = useCallback(
    (): SessionSearchCriteria => ({
      filterType: searchParams.get("filterType") as SessionSearchCriteria["filterType"],
      sessionId: searchParams.get("sessionId") as SessionSearchCriteria["sessionId"],
      sessionAlias: searchParams.get("sessionAlias") as SessionSearchCriteria["sessionAlias"]
    }),
    [searchParams]
  )

  const [searchCriteria, setSearchCriteria] = useState<SessionSearchCriteria>(initSearchCriteria)

  const {
    isFetching,
    error,
    data: sessions
  } = useQuery({
    enabled: isSearchQueryEnabled,
    queryKey: ["sessions", { searchCriteria, searchCount }],
    queryFn: async () => fetchSessions(searchCriteria!)
  })

  useEffect(() => {
    // Update search criteria from URL parameters
    const criteria = initSearchCriteria()
    setSearchCriteria(criteria)
  }, [initSearchCriteria, location])

  const handleSubmit = (criteria: SessionSearchCriteria): void => {
    // Set search criteria
    setSearchCriteria(criteria)
    setSearchCount((count) => count + 1)

    // Update URL parameters
    const urlParams = new URLSearchParams(queryString.stringify(criteria, { skipEmptyString: true, skipNull: true }))
    setSearchParams(urlParams)

    // Trigger search query
    if (!isSearchQueryEnabled) {
      setIsSearchQueryEnabled(true)
    }
  }

  return (
    <ViewContainer title="SESSIONS_PAGE.SESSIONS_SEARCH">
      <SessionSearchForm initialValues={searchCriteria} isPending={isFetching} onSubmit={handleSubmit} />

      <Divider aria-hidden="true" sx={{ marginY: 3 }} />

      <DynamicContent isLoading={isFetching} isVisible={!isNil(sessions)} error={error} errorMessage="SESSIONS_PAGE.ERROR_FETCHING_SESSIONS">
        {isEmpty(sessions) ? (
          <Alert severity="info">{t("SESSIONS_PAGE.NO_SESSIONS_FOUND")}</Alert>
        ) : (
          <>{sessions?.map((session, index) => <SessionSearchResult key={index} session={session} />)}</>
        )}
      </DynamicContent>
    </ViewContainer>
  )
}
