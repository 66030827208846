import { FC } from "react"
import { Field } from "react-final-form"
import { Box, Checkbox, TextField, FormControlLabel } from "@mui/material"
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { useTranslation } from "react-i18next"
import dayjs, { Dayjs } from "dayjs"

interface DisplayFieldProps {
  label: string
  name: string
  placeholder?: string
  variant?: "outlined" | "standard" | "filled"
  dataType?: "boolean" | "date" | "string" | "number"
}

export const DisplayField: FC<DisplayFieldProps> = ({ label, name, variant, dataType }) => {
  const { t } = useTranslation()

  const getValue = (value: Dayjs | number | string | null): Dayjs | null => {
    const dateValue = dayjs(value)
    return dateValue.isValid() ? dateValue : null
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Field name={name}>
        {({ input }) => {
          const checkboxControl = (
            <FormControlLabel
              sx={{ flex: 1 }}
              label={t(label)}
              control={<Checkbox name={input.name} checked={Boolean(input.value)} disabled={true} size="medium" />}
            />
          )

          const dateTimeControl = (
            <MuiDateTimePicker<Dayjs> label={t(label)} name={input.name} value={getValue(input.value)} disabled={true} readOnly={true} />
          )

          const textControl = (
            <TextField
              label={t(label)}
              name={input.name}
              value={input.value}
              size="medium"
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                disableUnderline: true,
                readOnly: true
              }}
              variant={variant}
            />
          )

          switch (dataType) {
            case "boolean":
              return checkboxControl

            case "date":
              return dateTimeControl

            case "number":
            case "string":
            default:
              return textControl
          }
        }}
      </Field>
    </Box>
  )
}
