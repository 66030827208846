import "./app.styles.scss"

import { StrictMode, Suspense } from "react"
import { createRoot } from "react-dom/client"
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"
import { AuthProvider } from "react-oauth2-code-pkce"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import "@/locales/i18n"

import { authConfig } from "@/constants"
import { ErrorPage } from "@/components/pages/ErrorPage"
import { LoadingPage } from "@/components/pages/LoadingPage"
import { ThemeProvider } from "@/providers/ThemeProvider"
import { history } from "@/history"
import { routes } from "@/routes"

export async function enableMocking() {
  if (window.location.protocol !== "https:" && process.env.NODE_ENV === "production") {
    return
  }

  const { worker } = await import("./mocks/browser")

  // `worker.start()` returns a Promise that resolves once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

const renderApp = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false, // default: true
        refetchOnWindowFocus: false, // default: true
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000)
      }
    }
  })

  const root = createRoot(document.getElementById("root")!)

  root.render(
    <StrictMode>
      <ErrorBoundary fallback={<ErrorPage />}>
        <Suspense fallback={<LoadingPage />}>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AuthProvider authConfig={authConfig}>
                <QueryClientProvider client={queryClient}>
                  <HistoryRouter history={history}>{routes}</HistoryRouter>
                </QueryClientProvider>
              </AuthProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </Suspense>
      </ErrorBoundary>
    </StrictMode>
  )
}

renderApp()
