import { FC } from "react"
import { Alert, LinearProgress, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

interface DynamicContentProps {
  isLoading: boolean
  isVisible?: boolean
  error: any
  errorMessage: string
  children: React.ReactNode
}

export const DynamicContent: FC<DynamicContentProps> = ({ isLoading, isVisible = true, error, errorMessage, children }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <LinearProgress />
  }

  if (error && errorMessage) {
    let errorTitle = null
    let errorDetails = null

    if (typeof error === "string") {
      errorTitle = error
      errorDetails = errorMessage
    } else if (error?.message ?? error?.reason) {
      errorTitle = errorMessage
      errorDetails = error?.message ?? error?.reason
    } else {
      errorTitle = errorMessage
    }

    return (
      <Alert severity="error">
        {errorTitle && <Typography variant="subtitle1">{t(errorTitle)}</Typography>}
        {errorDetails && <Typography variant="subtitle2">{t(errorDetails)}</Typography>}
      </Alert>
    )
  }

  if (!isVisible) {
    return null
  }

  return children
}
