import { FC, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { Alert, Box, Button, Collapse, Fade, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash-es"

import { applicationDefaults } from "@/constants"
import { fetchListing, updateListing } from "@/services/listings.service"
import { DynamicContent, ViewContainer } from "@/components/elements"
import { ListingDetails } from "./ListingDetails"
import { EditListingDetailsForm } from "./EditListingDetailsForm"
import { Listing } from "@/types"

const { ANIMATION_TIMEOUT } = applicationDefaults

export const ListingDetailsPage: FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const { listingId } = useParams()

  const isEditMode = /\/edit\/?$/.test(pathname)

  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false)
  const [updateError, setUpdateError] = useState<unknown>(null)

  const {
    isFetching,
    error,
    data: listing,
    refetch
  } = useQuery({
    enabled: ["number", "string"].includes(typeof listingId),
    queryKey: ["listings", listingId],
    queryFn: async () => fetchListing(listingId!)
  })

  const navigateToListingDetails = () => {
    navigate(`/listings/${listing?.importedListing.imported_listing_id}`)
  }

  const handleSubmit = async (updatedListing: Listing): Promise<void> => {
    const { imported_listing_id, listing_id } = updatedListing

    setIsUpdateInProgress(true)
    setUpdateError(null)

    try {
      await updateListing(imported_listing_id, listing_id, updatedListing)
    } catch (error) {
      setUpdateError(error)
    } finally {
      setIsUpdateInProgress(false)
    }

    await refetch()

    navigateToListingDetails()
  }

  const renderContent = () => {
    if (!listing) {
      return null
    }

    return (
      <Box>
        <Collapse in={isEditMode} timeout={ANIMATION_TIMEOUT}>
          <EditListingDetailsForm
            initialValues={listing.importedListing}
            onCancel={navigateToListingDetails}
            onSubmit={handleSubmit}
            isPending={isUpdateInProgress}
          />
        </Collapse>

        <Collapse in={!isEditMode} timeout={ANIMATION_TIMEOUT}>
          <ListingDetails listing={listing.importedListing} />
        </Collapse>
      </Box>
    )
  }

  const actionButtons = (
    <Fade in={!isEditMode} timeout={ANIMATION_TIMEOUT}>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => navigate(`/listings/${listingId}/edit`)}>
          {t("COMMON.EDIT")}
        </Button>
      </Stack>
    </Fade>
  )

  return (
    <ViewContainer title="LISTINGS_PAGE.LISITNG_DETAILS" actionButtons={actionButtons}>
      <DynamicContent isLoading={isFetching} error={error} errorMessage="LISTINGS_PAGE.ERROR_FETCHING_LISTING">
        <>
          {isEmpty(listing?.importedListing) ? <Alert severity="info">{t("LISTINGS_PAGE.NO_LISTING_FOUND", { listingId })}</Alert> : renderContent()}

          {updateError && <Alert severity="info">{t("LISTINGS_PAGE.ERROR_UPDATING_LISTING")}</Alert>}
        </>
      </DynamicContent>
    </ViewContainer>
  )
}
