import { FC } from "react"
import { Outlet } from "react-router-dom"
import { Box, Container, CssBaseline } from "@mui/material"

import { Navigation } from "@/components/elements"

export const MainLayout: FC = () => {
  return (
    <>
      <CssBaseline />
      <Container
        sx={{
          maxWidth: { xl: "xl" }
        }}
      >
        <Navigation />
        <Box
          component="main"
          sx={{
            marginTop: 3,
            paddingX: 1
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </>
  )
}
