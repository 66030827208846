import { FC } from "react"
import { Field } from "react-final-form"
import { Box, FormControl, FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material"
import { useTranslation } from "react-i18next"

interface CheckboxProps {
  label: string
  name: string
  disabled?: boolean
  readOnly?: boolean
}

export const Checkbox: FC<CheckboxProps> = ({ label, name, disabled, readOnly }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: "flex" }}>
      <Field name={name}>
        {({ input, meta }) => {
          const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (!disabled && !readOnly) {
              input.onChange(checked)
            }
          }

          const showError = meta.touched && meta.error

          const value = Boolean(input.value)

          return (
            <FormControl sx={{ flex: 1 }}>
              <FormControlLabel
                label={t(label)}
                control={
                  <MuiCheckbox
                    name={input.name}
                    value={value}
                    checked={Boolean(value)}
                    onChange={handleChange}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    size="small"
                    {...(showError
                      ? {
                          error: true,
                          helperText: <>⚠️ {t(meta.error)}</>
                        }
                      : {})}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                }
              />
            </FormControl>
          )
        }}
      </Field>
    </Box>
  )
}
