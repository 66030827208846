import { PaletteMode } from "@mui/material"
import { enUS } from "@mui/material/locale"
import { createTheme as createUITheme, Theme, ThemeOptions } from "@mui/material/styles"

const customThemeOptions: ThemeOptions = {
  // Customi MUI theme colors and styles
}

export function createTheme(themeMode: PaletteMode): Theme {
  const themeOptions: ThemeOptions = {
    ...customThemeOptions,
    palette: {
      mode: themeMode
    }
  }

  return createUITheme(themeOptions, enUS)
}
