import { FC, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Box, Divider, Grid, Typography } from "@mui/material"

interface ViewContainerProps {
  title?: string
  actionButtons?: ReactNode
  children?: ReactNode
}

export const ViewContainer: FC<ViewContainerProps> = ({ title, actionButtons, children }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Grid container justifyContent="space-between">
        {title && (
          <Grid item justifyContent="flex-start">
            <Typography color="text.primary" variant="h6">
              {t(title)}
            </Typography>
          </Grid>
        )}

        {actionButtons && (
          <Grid item justifyContent="flex-end">
            {actionButtons}
          </Grid>
        )}
      </Grid>

      <Divider aria-hidden="true" sx={{ marginTop: 1, marginBottom: 3 }} />

      <Box>{children ?? null}</Box>
    </Box>
  )
}
